<script lang="ts" setup>
import type { HTMLAttributes } from 'vue'
import { Primitive, type PrimitiveProps } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = withDefaults(defineProps<PrimitiveProps & { class?: HTMLAttributes['class'] }>(), {
  as: '',
})
</script>

<template>
  <Primitive v-if="as !== ''" :as="as" :as-child="asChild"
    :class="cn('transition-colors hover:text-foreground', props.class)">
    <slot />
  </Primitive>
  <NuxtLink v-else v-bind="$attrs" :class="cn('transition-colors hover:text-foreground', props.class)">
    <slot />
  </NuxtLink>
</template>
